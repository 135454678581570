import { createSlice } from "@reduxjs/toolkit"
import {loginAction, handleloginFailed, registerAction, 
  handleRegisterFailed,
  deleteManageClient,
  handleDeleteClientAction,
  addClientAction,
  handleAddClientAction,
  getManageClient,
  handleGetManageAction,
  getCountryListAction,
  getCityAction,
  handlegetCityListAction,
  getManageBrokerAction,
  handlegetManageBrokerAction,
  brokerAction,
  handleBrokerFailed,
  integrationSetupAction,
  handleIntegrationSetupFailed,
  AddBrokerAction,
  handleAddBrokerFailed,
  getViewBrokerAction,
  handleGetViewBrokerAction,
  verifyEmailAction,
  handleVerifyEmailFailed
   } from "./authActions"
import useJwt from '@src/auth/jwt/useJwt'
const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}
const config = useJwt.jwtConfig
const initialState = {
  status: "idle",
  getDataResponse: [],
  getBrokerInfo:{},
  getIntegration:{},
  getContrydata:{},
  getClientdata:{},
  getBrokerdata:{},
  getCitydata:{},
  brokerResponse:{},
  getBrokerView:{},
  registerResponse: {},
  userData:initialUser()
}
const authSlice = createSlice({
  name: 'auths',
  //createSlice will infer the state type from the 'initialState'argument
  initialState,
  reducers: {
    resetErrorAction: (state) => {
      delete state.authError
    },
    resetRegisterStatus: (state) => {
      delete state.brokerResponse
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(loginAction.fulfilled, (state, action) => {
          state.status = "idle"
        // state.userData = action.payload
        // localStorage.setItem('userData', JSON.stringify(action.payload.data))
       
        state.userData = action.payload
        state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
        localStorage.setItem('userData', JSON.stringify(action.payload.data.data))
        localStorage.setItem(config.storageTokenKeyName, JSON.stringify(action.payload.data.data.token))
      })
      .addCase(loginAction.rejected, handleloginFailed)

      .addCase(loginAction.pending, (state) => {
        state.status = "loading"
       
      })

      .addCase(getCountryListAction.fulfilled, (state, action) => {
        state.status = "idle"
        state.getContrydata = action.payload
        })
      .addCase(getCountryListAction.rejected, handleGetManageAction)
  
      .addCase(getCountryListAction.pending, (state) => {
        state.status = "loading"
      })
  
  
      .addCase(getCityAction.fulfilled, (state, action) => {
        state.status = "idle"
        state.getCitydata = action.payload
        })
      .addCase(getCityAction.rejected, handlegetCityListAction)
  
      .addCase(getCityAction.pending, (state) => {
        state.status = "loading"
      })
  
         .addCase(getManageClient.fulfilled, (state, action) => {
          state.status = "idle"
          state.getClientdata = action.payload
          })
        .addCase(getManageClient.rejected, handleGetManageAction)
  
        .addCase(getManageClient.pending, (state) => {
          state.status = "loading"
        })
  
  
        // get All broker slice
  
        .addCase(getManageBrokerAction.fulfilled, (state, action) => {
          state.status = "idle"
          state.getBrokerdata = action.payload
          })
        .addCase(getManageBrokerAction.rejected, handlegetManageBrokerAction)
  
        .addCase(getManageBrokerAction.pending, (state) => {
          state.status = "loading"
        })
  // get single by id broker slice
  
  .addCase(getViewBrokerAction.fulfilled, (state, action) => {
    state.status = "idle"
    state.getBrokerView = action.payload
    })
  .addCase(getViewBrokerAction.rejected, handleGetViewBrokerAction)
  
  .addCase(getViewBrokerAction.pending, (state) => {
    state.status = "loading"
  })
  
        // add broke action
  
        .addCase(AddBrokerAction.fulfilled, (state, action) => {
  
          state.status = "idle"
          state.brokerResponse = action.payload
  
        })
        .addCase(AddBrokerAction.rejected, handleAddBrokerFailed)
  
        .addCase(AddBrokerAction.pending, (state) => {
          state.status = "loading"
        })
  
        .addCase(registerAction.fulfilled, (state, action) => {
  
          state.status = "idle"
          state.registerResponse = action.payload
  
        })
        .addCase(registerAction.rejected, handleRegisterFailed)
  
        .addCase(registerAction.pending, (state) => {
          state.status = "loading"
        })
  //sent email
  
        .addCase(verifyEmailAction.fulfilled, (state, action) => {
  
          state.status = "idle"
          state.emailStatus = action.payload
  
        })
        .addCase(verifyEmailAction.rejected, handleVerifyEmailFailed)
  
        .addCase(verifyEmailAction.pending, (state) => {
          state.status = "loading"
        })
  
  
        //broker info
  
        .addCase(brokerAction.fulfilled, (state, action) => {
  
          state.status = "idle"
          state.getBrokerInfo = action.payload
  
        })
        .addCase(brokerAction.rejected, handleBrokerFailed)
  
        .addCase(brokerAction.pending, (state) => {
          state.status = "loading"
        })
  
        //integration setup 
  
        .addCase(integrationSetupAction.fulfilled, (state, action) => {
  
          state.status = "idle"
          state.getIntegration = action.payload
  
        })
        .addCase(integrationSetupAction.rejected, handleIntegrationSetupFailed)
  
        .addCase(integrationSetupAction.pending, (state) => {
          state.status = "loading"
        })
  
        //
  
        .addCase(addClientAction.fulfilled, (state, action) => {
          state.status = "idle"
          state.getDataResponse = [action.payload, ...state.getDataResponse]
  
        })
        .addCase(addClientAction.rejected, handleAddClientAction)
  
        .addCase(addClientAction.pending, (state) => {
          state.status = "loading"
        })
  
        .addCase(deleteManageClient.fulfilled, (state, action) => {
          state.status = "idle"
          state.getClientdata = state.getClientdata.data.filter((el) => el.id === action.payload)
        })
        .addCase(deleteManageClient.rejected, handleDeleteClientAction)
  
        .addCase(deleteManageClient.pending, (state) => {
          state.status = "loading"
        })

  }
})
export const { resetErrorAction, resetRegisterStatus } = authSlice.actions
export default authSlice.reducer
export const getResponse = (state) => state && state.auths && state.auths.userData && state.auths.userData.data && state.auths.userData.data.data
export const selectStatus = (state) => state.auths.status
// export const selectResetEmail = (state) => state.auth.data && state.auth.data.user && state.auth.data.user.email
 export const selectAuthError = (state) => state.auths.authError
export const selectRegisterStatus = (state) => state && state.auths && state.auths.registerResponse

export const getRegisterStatus = (state) => state && state.auths && state.auths.getDataResponse
export const getBrokerInfoStatus = (state) => state && state.auths && state.auths.getBrokerInfo
export const getIntegraionStatus = (state) => state && state.auths && state.auths.getIntegration
export const getAddBrokerResponse = (state) => state && state.auths && state.auths.brokerResponse
export const selectEmailStatus = (state) => state && state.auths && state.auths.emailStatus
export const getclientDatastatus = (state) => state && state.auths && state.auths.getClientdata
export const getbrokerDatastatus = (state) => state && state.auths && state.auths.getBrokerdata
export const getBrokerViewstatus = (state) => state && state.auths && state.auths.getBrokerView
export const getCountrystatus = (state) => state && state.auths && state.auths.getContrydata
export const getCitystatus = (state) => state && state.auths && state.auths.getCitydata

export const selectdeleteManageStatus = (state) => state.auths.getClientdata
console.log("selectdeleteManageStatus", selectdeleteManageStatus)
