import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from 'axios'
import { baseUrl } from "../config"
     //add users 

     export const addUser = createAsyncThunk(
      "user/add",
      async (state, { rejectWithValue }) => {
         console.log("add>>>>>", state)
        //  const token = "4|mK2FRRtVduffgWlskJH0ycI7HzIswJJ0fXGXISXs"
        try {    
          // console.log("habeel") 
        
          const res = await axios.post(`${baseUrl}users`, state,
            { headers: { 
                "content-type": "multipart/form-data",
              Accept:"application/json"
              } }
 
          )   
          return res
             } catch (err) {
                console.log("error>", err)
          return rejectWithValue(err.response.data)
        }
      }
     )
    
     export const handleAddUserFailed = (state, action) => {
      state.status = "failed"
      state.authError = action.payload
     }


          //add users 

          export const getAllUsers = createAsyncThunk(
            "user/getusers",
            async (state, { rejectWithValue }) => {
               console.log(state)
              //  const token = "4|mK2FRRtVduffgWlskJH0ycI7HzIswJJ0fXGXISXs"
              try {    
              
                const res = await axios.get(`${baseUrl}users`, state,
                  { headers: { 
                    Accept:"application/json"
                    } }
       
                )   
                return res
                   } catch (err) {
                      console.log("error>", err)
                return rejectWithValue(err.response.data)
              }
            }
           )
          
           export const handlegetAllUsersFailed = (state, action) => {
            state.status = "failed"
            state.authError = action.payload
           }
      

               //get single users 

          export const getSingleUser = createAsyncThunk(
            "user/getSingleUser",
            async (state, { rejectWithValue }) => {
               console.log("single", state)
              //  const token = "4|mK2FRRtVduffgWlskJH0ycI7HzIswJJ0fXGXISXs"
              try {    
              
                const res = await axios.get(`${baseUrl}getUser?id=${state}`, 
                  { headers: { 
                    Accept:"application/json"
                    } }
       
                )   
                return res
                   } catch (err) {
                     
                return rejectWithValue(err.response.data)
              }
            }
           )
          
           export const handleGetSingleUserFailed = (state, action) => {
            state.status = "failed"
            state.authError = action.payload
           }
      
              //get single users 

          export const deleteUser = createAsyncThunk(
            "user/deleteUser",
            async (state, { rejectWithValue }) => {
               console.log("single", state)
              //  const token = "4|mK2FRRtVduffgWlskJH0ycI7HzIswJJ0fXGXISXs"
              try {    
              
                const res = await axios.delete(`${baseUrl}users/user?id=${state}`, 
               
                  { headers: { 
                    Accept:"application/json"
                    } }
       
                )   
                return res
                   } catch (err) {
                     
                return rejectWithValue(err.response.data)
              }
            }
           )
          
           export const handledeleteUserFailed = (state, action) => {
            state.status = "failed"
            state.authError = action.payload
           }
      

    //update users

     export const updateUser = createAsyncThunk(
      "user/updateUser",
      async (state, { rejectWithValue }) => {
        console.log("add>>>>> update user hook api", state)

        //  const token = "4|mK2FRRtVduffgWlskJH0ycI7HzIswJJ0fXGXISXs"
        try {

          // console.log("habeel")
          const res = await axios.patch(`${baseUrl}users/users/${state.id}`, state,

            { headers: {

                "content-type": "multipart/form-data",

              Accept:"application/json"

              } }
          )
          return res
             } catch (err) {
                console.log("error>", err)
          return rejectWithValue(err.response.data)
        }

      }

     )

     export const handleupdateUserFailed = (state, action) => {

      state.status = "failed"

      state.authError = action.payload

     }