import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from 'axios'
import { baseUrl } from "../config"
 export const loginAction = createAsyncThunk(
      "auth/loginAction",
      async (state, { rejectWithValue }) => {
        console.log("state=>>>", state)
       
        try {         
          const res = await axios.post(`${baseUrl}userLogin`, state,
            { headers: { 
                "content-type": "multipart/form-data",
                Accept:"application/json"
         } }
 
          )   
          return res
             } catch (err) {
                console.log("error>", err)
          return rejectWithValue(err.response.data)
        }
      }
     )
    
     export const handleloginFailed = (state, action) => {
      state.status = "failed"
      state.authError = action.payload
     }

     export const verifyEmailAction = createAsyncThunk(
      "auth/verifyEmailAction",
      async (state, { rejectWithValue }) => {
        try {
          // const res = await sendRequest("POST", "auth/verify?email=" + email, { otp })
          return state
    
        } catch (err) {
          return rejectWithValue(err.response.data)
        }
      }
    )
    export const handleVerifyEmailFailed = (state, action) => {
      state.status = "failed"
      state.authError = action.payload
    }
    
    export const registerAction = createAsyncThunk(
      "auth/registerAction",
      async (state, { rejectWithValue }) => {
        console.log("state", state)
        return state
    
        // try {
        //   const res = await sendRequest("POST", state)
        //   console.log("res", res)
        //   return res
          
        // } catch (err) {
        //   return rejectWithValue(err.response.data)
        // }
    
        return rejectWithValue(response.data)
      }
    )
    
    export const handleRegisterFailed = (state, action) => {
      state.status = "failed"
      state.authError = action.payload
    }
     // add broker 

export const AddBrokerAction = createAsyncThunk(
  "auth/AddBrokerAction",
  async (state, { rejectWithValue }) => {
    console.log("brokerstate=>>>", state)

    try {
    // const URL = "PMAK-634942f905490903fe4009a1-976fee060baa93567d6982fc57ec6d690e"

    const res = await axios.post(
      `http://192.168.10.196:8069/api/broker/store`, state
    )

    console.log("res>>>>", res)
      return res  
      console.log("res>>>>", res)
      
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const handleAddBrokerFailed = (state, action) => {
  state.status = "failed"
  state.authError = action.payload
}

//broker information state Action 
export const brokerAction = createAsyncThunk(
  "auth/brokerAction",
  async (state, { rejectWithValue }) => {
    console.log("state", state)
    return state
    return rejectWithValue(response.data)
  }
)

export const handleBrokerFailed = (state, action) => {
  state.status = "failed"
  state.authError = action.payload
}
// integration setup 
export const integrationSetupAction = createAsyncThunk(
  "auth/integrationSetupAction",
  async (state, { rejectWithValue }) => {
    console.log("state", state)
    return state
    return rejectWithValue(response.data)
  }
)

export const handleIntegrationSetupFailed = (state, action) => {
  state.status = "failed"
  state.authError = action.payload
}

export const addClientAction = createAsyncThunk(
  "auth/addClientAction",
  async (state, { rejectWithValue }) => {
    console.log("state", state)
    return state

    // try {
    //   const res = await sendRequest("POST", state)
    //   console.log("res", res)
    //   return res
      
    // } catch (err) {
    //   return rejectWithValue(err.response.data)
    // }

    return rejectWithValue(response.data)
  }
)

export const handleAddClientAction = (state, action) => {
  state.status = "failed"
  state.authError = action.payload
}

export const deleteManageClient = createAsyncThunk(
  "auth/deleteManageClient",
  async (state, { rejectWithValue }) => {

    return state
    // try {
    //   const res = await sendRequest("DELETE", `chain?chainId=${state}`);
    //   return res;
    // }
    // catch (err) {
    //   return rejectWithValue(err.response.data);
    // }

    return rejectWithValue(err.response.data)
  }
)

export const handleDeleteClientAction = (state, action) => {
  state.status = "failed"
  state.managementError = action.payload
}


// export const forgotPasswordAction = createAsyncThunk(
//   "auth/forgotPasswordAction",
//   async ({ email }, { rejectWithValue }) => {
//     try {
//       const res = await sendRequest("POST", "auth/forgot-password", { email })
//       return res
//     } catch (err) {
//       return rejectWithValue(err.response.data)

//     }
//   }
// )
// export const handleForgotPasswordFailed = (state, action) => {
//   state.status = "failed"
//   state.authError = action.payload
// }

// export const activationAction = createAsyncThunk(
//   "auth/activationAction",
//   async (state, { rejectWithValue }) => {
//     try {
//       const res = await sendRequest("POST", "accounts/activate", state)
//       return res
//     }
//     catch (err) {
//       return rejectWithValue(err.response.data)
//     }
//   }
// )


export const getManageClient = createAsyncThunk(
  "management/getManageClient",
  async (state = "", { rejectWithValue }) => {
   
   try {
    const URL = "PMAK-634942f905490903fe4009a1-976fee060baa93567d6982fc57ec6d690e"

    const res = await axios.get(
      `https://042a6229-d7e1-4417-8367-8df71c665a96.mock.pstmn.io/clients`,
      {
        headers: {
          'X-Api-Key': URL
        }
      },
      state
    )
      return res  
      
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const handleGetManageAction = (state, action) => {
  state.status = "failed"
  state.authError = action.payload
}

// get broker data 


export const getManageBrokerAction = createAsyncThunk(
  "management/getManageBrokerAction",
  async (state = "", { rejectWithValue }) => {
   
   try {
    const URL = "PMAK-634942f905490903fe4009a1-976fee060baa93567d6982fc57ec6d690e"

    const res = await axios.get(
      `https://042a6229-d7e1-4417-8367-8df71c665a96.mock.pstmn.io/broker`,
      {
        headers: {
          'X-Api-Key': URL
        }
      },
      state
    )
      return res  
      
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const handlegetManageBrokerAction = (state, action) => {
  state.status = "failed"
  state.authError = action.payload
}

// get single brokr view
export const getViewBrokerAction = createAsyncThunk(
  "management/getViewBrokerAction",
  async (state, { rejectWithValue }) => {
   
   try {
    const URL = "PMAK-634942f905490903fe4009a1-976fee060baa93567d6982fc57ec6d690e"

    const res = await axios.get(
      `https://042a6229-d7e1-4417-8367-8df71c665a96.mock.pstmn.io/broker/${state}`,
      {
        headers: {
          'X-Api-Key': URL
        }
      },
      state
    )
      return res  
      
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const handleGetViewBrokerAction = (state, action) => {
  state.status = "failed"
  state.authError = action.payload
}


export const getCountryListAction = createAsyncThunk(
  "management/getCountryListAction",
  async (state = "", { rejectWithValue }) => {
   
   try {

    const res = await axios.get(
      `http://192.168.10.196:8069/api/world/country`, state
      )

      // const res = await sendRequest("GET", '/clients', {
      //     headers: {
      //       'X-Api-Key': URL
      //     }
      //   })
  
      return res  
      
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const handlegetCountryListAction = (state, action) => {
  state.status = "failed"
  state.authError = action.payload
}


export const getCityAction = createAsyncThunk(
  "management/getCityAction",
  async (state, { rejectWithValue }) => {
   console.log("=>>>", state)
   try {

    const res = await axios.get(
      `http://192.168.10.196:8069/api/world/country/fetch/${state}`
      )

      // const res = await sendRequest("GET", '/clients', {
      //     headers: {
      //       'X-Api-Key': URL
      //     }
      //   })
      
      return res  
      
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const handlegetCityListAction = (state, action) => {
  state.status = "failed"
  state.authError = action.payload
}
// export const handleActivationFailed = (state, action) => {
//   state.status = "failed"
//   state.authError = action && action.payload
// }

// export const verifyCodeAction = createAsyncThunk(
//   "auth/verifyCodeAction",
//   async (state, { rejectWithValue }) => {
//     try {
//       const res = await sendRequest("POST", "accounts/verified", state)
//       return res
//     }
//     catch (err) {
//       return rejectWithValue(err.response.data)
//     }
//   }
// )
// export const handleVerifiedCodeFailed = (state, action) => {
//   state.status = "failed"
//   state.authError = action.payload
// }
