import { createSlice } from "@reduxjs/toolkit"
import {addRoleAction, AssignedRole, deleteRole, getAllRole,  handleAddRoleFailed,  handleAllRoleFailed, handleAssignedRoleFailed, handleDeleteRoleFailed, handleOnPageloadFailed, handlepermissionListFailed, handleunAssignedRoleFailed, handleupdateRoleFailed, onRolePageload, permissionList, unAssignedRole, updateRole } from "./rolesActions"

const initialState = {
  status: "idle",
  roles:{}
 

}

const rolesSlice = createSlice({
  name: 'roles',
  //createSlice will infer the state type from the 'initialState'argument
  initialState,
  reducers: {
    resetErrorAction: (state) => {
      delete state.authError
    },
    resetRegisterStatus: (state) => {
      delete state.brokerResponse
    },
    resetUnAssignedStatus: (state) => {
      delete state.unAssigned
    },
    resetAssignedStatus: (state) => {
      delete state.assignedRole
    },
    resetUpdateRoleStatus: (state) => {
      delete state.updateRole
    },

    resetDeleteRoleStatus: (state) => {
      delete state.deletedRole
    }
  },

  extraReducers: (builder) => {
    builder

     // On page load      
     .addCase(onRolePageload.fulfilled, (state, action) => {
      state.status = "idle"
      state.onPageLoad = action.payload
      })
    .addCase(onRolePageload.rejected, handleOnPageloadFailed)

    .addCase(onRolePageload.pending, (state) => {
      state.status = "loading"
    })

       // get all permission list     
       .addCase(permissionList.fulfilled, (state, action) => {
        state.status = "idle"
        state.permissionList = action.payload
        })
      .addCase(permissionList.rejected, handlepermissionListFailed)

      .addCase(permissionList.pending, (state) => {
        state.status = "loading"
      })

    // Add role slice  
    .addCase(addRoleAction.fulfilled, (state, action) => {
      state.status = "idle"
      state.addRole = action.payload
      })
    .addCase(addRoleAction.rejected, handleAddRoleFailed)

    .addCase(addRoleAction.pending, (state) => {
      state.status = "loading"
    })

      // get all role      
      .addCase(getAllRole.fulfilled, (state, action) => {
        state.status = "idle"
        state.roles = action.payload
        })
      .addCase(getAllRole.rejected, handleAllRoleFailed)

      .addCase(getAllRole.pending, (state) => {
        state.status = "loading"
      })

       // delete role      
       .addCase(deleteRole.fulfilled, (state, action) => {
        state.status = "idle"
        state.deletedRole = action.payload
        })
      .addCase(deleteRole.rejected, handleDeleteRoleFailed)

      .addCase(deleteRole.pending, (state) => {
        state.status = "loading"
      })

      // unAssigned role      
      .addCase(unAssignedRole.fulfilled, (state, action) => {
        state.status = "idle"
        state.unAssigned = action.payload
        })
      .addCase(unAssignedRole.rejected, handleunAssignedRoleFailed)

      .addCase(unAssignedRole.pending, (state) => {
        state.status = "loading"
      })

       // unpdate role      
       .addCase(updateRole.fulfilled, (state, action) => {
        state.status = "idle"
        state.updateRole = action.payload
        })
      .addCase(updateRole.rejected, handleupdateRoleFailed)

      .addCase(updateRole.pending, (state) => {
        state.status = "loading"
      })

      // Assigned role      
      .addCase(AssignedRole.fulfilled, (state, action) => {
        state.status = "idle"
        state.assignedRole = action.payload
        })
      .addCase(AssignedRole.rejected, handleAssignedRoleFailed)

      .addCase(AssignedRole.pending, (state) => {
        state.status = "loading"
      })
  }
})
export const { resetErrorAction, resetDeleteRoleStatus, resetRegisterStatus, resetUnAssignedStatus, resetAssignedStatus, resetUpdateRoleStatus} = rolesSlice.actions
export default rolesSlice.reducer
export const error = (state) => state && state.roles
export const roleAuthError = (state) => state.roles.authError
export const roleData = (state) => state && state.roles && state.roles.roles
export const onPageLoadResponse = (state) => state && state.roles && state.roles.onPageLoad && state.roles.onPageLoad.data
export const permissionListResponse  = (state) => state && state.roles && state.roles.permissionList && state.roles.permissionList.data
export const AddRoles  = (state) => state && state.roles && state.roles.addRole && state.roles.addRole.data &&   state.roles.addRole.data.data

export const unAssignedResponse = (state) => state && state.roles && state.roles.unAssigned && state.roles.unAssigned.data
export const AssignedRoleResponse = (state) => state && state.roles && state.roles.assignedRole && state.roles.assignedRole.data
export const deleteRoleResponse = (state) => state && state.roles && state.roles.deletedRole  && state.roles.deletedRole.data 
export const updateRoleResponse = (state) => state && state.roles && state.roles.updateRole && state.roles.updateRole.data