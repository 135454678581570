import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from 'axios'
import { baseUrl } from "../config"

     //add permission
     export const AddPermissionAction = createAsyncThunk(
      "permission/add",
      async (state, { rejectWithValue }) => {
        console.log("state value into the store", state)
        
         const token = "4|mK2FRRtVduffgWlskJH0ycI7HzIswJJ0fXGXISXs"
        try {    
          const res = await axios.post(`${baseUrl}permissions`, state,
            { headers: { 
              Authorization: `Bearer ${token}`,
              "content-type": "multipart/form-data",
              Accept:"application/json"
              } }
 
          )   
          return res
             } catch (err) {

              ///show the errror message
               console.log('reposn message', err.response.data)
                console.log("error>", err)
          return rejectWithValue(err.response.data)
        }
      }
     )
    
     export const handleAddPermissionFailed = (state, action) => {
      state.status = "failed"
      state.authError = action.payload
     }


     //get permission api 

     export const getPermissionAction = createAsyncThunk(
      "permission/view",
      async (state, { rejectWithValue }) => {
        
         const token = "660|m5nK6qdfaWANmRgXGtTw5omGHQO4LMSFb0BZ8gvn"
        try {    
          const res = await axios.get(`${baseUrl}permissions`, state,
            { headers: { 
              Authorization: `Bearer ${token}`,
              "content-type": "multipart/form-data",
              Accept:"application/json"
              } }
 
          )   
          return res
             } catch (err) {
                console.log("error>", err)
          return rejectWithValue(err.response.data)
        }
      }
     )
    
     export const handleGetPermissionFailed = (state, action) => {
      state.status = "failed"
      state.authError = action.payload
     }

     export const getAllModuleAction = createAsyncThunk(
      "permission/getAllModuleAction",
      async (state, { rejectWithValue }) => {
        
         const token = "4|mK2FRRtVduffgWlskJH0ycI7HzIswJJ0fXGXISXs"
        try {    
          const res = await axios.get(`${baseUrl}modules`, state,
            { headers: { 
              Authorization: `Bearer ${token}`,
              "content-type": "multipart/form-data",
              Accept:"application/json"
              } }
 
          )   
          return res
             } catch (err) {
                console.log("error>", err)
          return rejectWithValue(err.response.data)
        }
      }
     )
    
     export const handleGetAllModuleFailed = (state, action) => {
      state.status = "failed"
      state.authError = action.payload
     }


     //get all functionality

     export const getAllFunctionalityAction = createAsyncThunk(
      "permission/getAllFunctionalityAction",
      async (state, { rejectWithValue }) => {
        
         const token = "4|mK2FRRtVduffgWlskJH0ycI7HzIswJJ0fXGXISXs"
        try {    
          const res = await axios.get(`${baseUrl}getfunctionlaity/${state}`, 
            { headers: { 
              Authorization: `Bearer ${token}`,
              "content-type": "multipart/form-data",
              Accept:"application/json"
              } }
 
          )   
          return res
             } catch (err) {
                console.log("error>", err)
          return rejectWithValue(err.response.data)
        }
      }
     )
    
     export const handleGetFunctionalityFailed = (state, action) => {
      state.status = "failed"
      state.authError = action.payload
     }

     //delete functionality
    //  export const deletefunctionlity = createAsyncThunk(
    //   "permission/deletefunctionlity",
    //   async (state, { rejectWithValue }) => {
    //     console.log('permission deletel api call', state)
    //     const token = "4|mK2FRRtVduffgWlskJH0ycI7HzIswJJ0fXGXISXs"
    //     try {
    //       // console.log("habeel") 
    
    //       // const res = 'hello'
    //       await axios.delete(`${baseUrl}permissions/permission?id=${state.id}`,
    //         {
    //           headers: {
    //             Authorization: `Bearer ${token}`,
    //             "content-type": "multipart/form-data",
    //             Accept: "application/json"
    //           }
    //         }
    
    //       )
    //       return res
    //     } catch (err) {
    //       console.log("error>", err)
    //       return rejectWithValue(err.response.data)
    //     }
    //   }
    // )
    
    // export const handleDeleteFunctionlityFailed = (state, action) => {
    //   state.status = "failed"
    //   state.authError = action.payload
    // }


    //delete 


     //get all functionality

     export const deletefunctionlity = createAsyncThunk(
      "permission/deletefunctionlity",
      async (state, { rejectWithValue }) => {
        
         const token = "4|mK2FRRtVduffgWlskJH0ycI7HzIswJJ0fXGXISXs"
        try {    
          const res = await axios.delete(`${baseUrl}permissions/permission?id=${state}`, 
            { headers: { 
              Authorization: `Bearer ${token}`,
              "content-type": "multipart/form-data",
              Accept:"application/json"
              } }
 
          )   
          return res
             } catch (err) {
                console.log("error>", err)
          return rejectWithValue(err.response.data)
        }
      }
     )
    
     export const handleDeleteFunctionlityFailed = (state, action) => {
      state.status = "failed"
      state.authError = action.payload
     }
    // updata functionality 

export const updatefunctionality = createAsyncThunk(
  "functionality/upDatefunctionality",
  async (state = "", { rejectWithValue }) => {
    console.log('updates functionality name', state)
    // const token = "4|mK2FRRtVduffgWlskJH0ycI7HzIswJJ0fXGXISXs"
    try {
      // console.log("habeel") 

      // const res = await axios.patch(`${baseUrl}roles/role?id=${state.id}&name=${state.roleName}&status=${state.status}`,
      //   {
      //     headers: {
      //       Authorization: `Bearer ${token}`,
      //       "content-type": "multipart/form-data",
      //       Accept: "application/json"
      //     }
      //   }

      // )
      return res
    } catch (err) {
      console.log("error>", err)
      return rejectWithValue(err.response.data)
    }
  }
)

export const handleupdateFunctionalityFailed = (state, action) => {
  state.status = "failed"
  state.authError = action.payload
}