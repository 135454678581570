import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from 'axios'
import { baseUrl } from "../config"

// onpage load api call

export const onRolePageload = createAsyncThunk(
  "roles/onPageLoad",
  async (state = "", { rejectWithValue }) => {
    console.log("roleAction >>", state)
    const token = "4|mK2FRRtVduffgWlskJH0ycI7HzIswJJ0fXGXISXs"
    try {
      const res = await axios.get(`${baseUrl}onPageload`, state,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json"
          }
        }

      )
      return res
      // return res
    } catch (err) {
      console.log("error>", err)
      return rejectWithValue(err.response.data)
    }
  }
)

export const handleOnPageloadFailed = (state, action) => {
  state.status = "failed"
  state.authError = action.payload
}

export const permissionList = createAsyncThunk(
  "roles/permissionList",
  async (state = "", { rejectWithValue }) => {
    console.log("roleAction >>", state)
    const token = "4|mK2FRRtVduffgWlskJH0ycI7HzIswJJ0fXGXISXs"
    try {
      const res = await axios.get(`${baseUrl}listPermission`, state,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json"
          }
        }

      )
      return res
      // return res
    } catch (err) {
      console.log("error>", err)
      return rejectWithValue(err.response.data)
    }
  }
)

export const handlepermissionListFailed = (state, action) => {
  state.status = "failed"
  state.authError = action.payload
}


// Add role Api Call
export const addRoleAction = createAsyncThunk(
  "roles/addRoleAction",
  async (state, { rejectWithValue }) => {
    console.log("roleAction >>", state)
    const token = "660|m5nK6qdfaWANmRgXGtTw5omGHQO4LMSFb0BZ8gvn"
    try {
      const res = await axios.post(`${baseUrl}roles`, state,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json"
          }
        }

      )
      return res
      // return res
    } catch (err) {
      console.log("error>", err)
      return rejectWithValue(err.response.data)
    }
  }
)

export const handleAddRoleFailed = (state, action) => {
  state.status = "failed"
  state.authError = action.payload
}


//get all role 

export const getAllRole = createAsyncThunk(
  "roles/view",
  async (state, { rejectWithValue }) => {
    console.log("roleAction >>", state)
    const token = "4|mK2FRRtVduffgWlskJH0ycI7HzIswJJ0fXGXISXs"
    try {
      // console.log("habeel") 

      const res = await axios.get(`${baseUrl}roles`, state,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json"
          }
        }

      )
      return res


      // return res
    } catch (err) {
      console.log("error>", err)
      return rejectWithValue(err.response.data)
    }
  }
)

export const handleAllRoleFailed = (state, action) => {
  state.status = "failed"
  state.authError = action.payload
}
//delete role 

export const deleteRole = createAsyncThunk(
  "roles/deleteRole",
  async (state = "", { rejectWithValue }) => {
    console.log(state)
    const token = "4|mK2FRRtVduffgWlskJH0ycI7HzIswJJ0fXGXISXs"
    try {
      // console.log("habeel") 

      const res = await axios.delete(`${baseUrl}roles/role/?id=${state.id}`, state,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "multipart/form-data",
            Accept: "application/json"
          }
        }

      )
      return res
    } catch (err) {
      console.log("error>", err)
      return rejectWithValue(err.response.data)
    }
  }
)

export const handleDeleteRoleFailed = (state, action) => {
  state.status = "failed"
  state.authError = action.payload
}

//usassigned role 

export const unAssignedRole = createAsyncThunk(
  "roles/unAssigned",
  async (state = "", { rejectWithValue }) => {
    console.log(state)
    const token = "4|mK2FRRtVduffgWlskJH0ycI7HzIswJJ0fXGXISXs"
    try {
      // console.log("habeel") 

      const res = await axios.put(`${baseUrl}unassignRole/${state.id}`, state,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "multipart/form-data",
            Accept: "application/json"
          }
        }

      )
      return res
    } catch (err) {
      console.log("error>", err)
      return rejectWithValue(err.response.data)
    }
  }
)

export const handleunAssignedRoleFailed = (state, action) => {
  state.status = "failed"
  state.authError = action.payload
}

// updata role 

export const updateRole = createAsyncThunk(
  "roles/upDateRole",
  async (state = "", { rejectWithValue }) => {
    console.log(state)
    const token = "4|mK2FRRtVduffgWlskJH0ycI7HzIswJJ0fXGXISXs"
    try {
      // console.log("habeel") 

      const res = await axios.patch(`${baseUrl}roles/role?id=${state.id}&name=${state.roleName}&status=${state.status}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "multipart/form-data",
            Accept: "application/json"
          }
        }

      )
      return res
    } catch (err) {
      console.log("error>", err)
      return rejectWithValue(err.response.data)
    }
  }
)

export const handleupdateRoleFailed = (state, action) => {
  state.status = "failed"
  state.authError = action.payload
}

//assigned role 

export const AssignedRole = createAsyncThunk(
  "roles/Assigned",
  async (state = "", { rejectWithValue }) => {
    console.log(state)
    const token = "4|mK2FRRtVduffgWlskJH0ycI7HzIswJJ0fXGXISXs"
    try {
      // console.log("habeel") 

      const res = await axios.post(`${baseUrl}assignRole`, state,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json"
          }
        }

      )
      return res
    } catch (err) {
      console.log("error>", err)
      return rejectWithValue(err.response.data)
    }
  }
)

export const handleAssignedRoleFailed = (state, action) => {
  state.status = "failed"
  state.authError = action.payload
}

