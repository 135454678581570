import { createSlice } from "@reduxjs/toolkit"
import { AddPermissionAction, deletefunctionlity, getAllFunctionalityAction, getAllModuleAction, getPermissionAction, handleAddPermissionFailed, handleDeleteFunctionlityFailed, handleGetAllModuleFailed, handleGetFunctionalityFailed, handleGetPermissionFailed, handleupdateFunctionalityFailed, updatefunctionality } from "./permissionActions"

const initialState = {
  status: "idle",
  roles:{},
  permission:{}

}

const permissionSlice = createSlice({
  name: 'permission',
  //createSlice will infer the state type from the 'initialState'argument
  initialState,
  reducers: {
    resetErrorAction: (state) => {
      delete state.authError
    },
    resetRegisterStatus: (state) => {
      delete state.brokerResponse
    },
    resetDeleteStatus: (state) => {
      delete state.deletedFunctionality
    }
  },

  extraReducers: (builder) => {
    builder
      //Add permission slice

      .addCase(AddPermissionAction.fulfilled, (state, action) => {
        state.status = "idle"
        state.addPermission = action.payload
        })
      .addCase(AddPermissionAction.rejected, handleAddPermissionFailed)

      .addCase(AddPermissionAction.pending, (state) => {
        state.status = "loading"
      })

       //get permission 

       .addCase(getPermissionAction.fulfilled, (state, action) => {
        state.status = "idle"
        state.permission = action.payload
        })
      .addCase(getPermissionAction.rejected, handleGetPermissionFailed)

      .addCase(getPermissionAction.pending, (state) => {
        state.status = "loading"
      })
       //get module 

       .addCase(getAllModuleAction.fulfilled, (state, action) => {
        state.status = "idle"
        state.module = action.payload
        })
      .addCase(getAllModuleAction.rejected, handleGetAllModuleFailed)

      .addCase(getAllModuleAction.pending, (state) => {
        state.status = "loading"
      })

       //get  functionality

       .addCase(getAllFunctionalityAction.fulfilled, (state, action) => {
        state.status = "idle"
        state.functionality = action.payload
        })
      .addCase(getAllFunctionalityAction.rejected, handleGetFunctionalityFailed)

      .addCase(getAllFunctionalityAction.pending, (state) => {
        state.status = "loading"
      })

       // delete functionality      
       .addCase(deletefunctionlity.fulfilled, (state, action) => {
        state.status = "idle"
        state.deletedFunctionality = action.payload
        })
      .addCase(deletefunctionlity.rejected, handleDeleteFunctionlityFailed)

      .addCase(deletefunctionlity.pending, (state) => {
        state.status = "loading"
      })

        // unpdate functionality       
        .addCase(updatefunctionality.fulfilled, (state, action) => {
        state.status = "idle"
        state.updatefunctionality = action.payload
        })
      .addCase(updatefunctionality.rejected, handleupdateFunctionalityFailed)

      .addCase(updatefunctionality.pending, (state) => {
        state.status = "loading"
      })

  }
})
export const { resetErrorAction, resetDeleteStatus, resetRegisterStatus } = permissionSlice.actions
export default permissionSlice.reducer
export const permissionAuthError = (state) => state.permission.authError
export const roleData = (state) => state && state.permission && state.permission.roles
export const getPermission = (state) => state && state.permission && state.permission.permission
export const AddPermission = (state) => state && state.permission && state.permission.addPermission  && state.permission.addPermission.data
export const PermissionsResponse = (state) => state && state.permission
export const deleteFuncationalityResponse = (state) => state && state.permission && state.permission.deletedFunctionality && state.permission.deletedFunctionality.data
export const getModuleResponse = (state) => state && state.permission && state.permission.module && state.permission.module.data && state.permission.module.data.modules 
export const getfunctionalityResponse = (state) => state && state.permission && state.permission.functionality  && state.permission.functionality.data && state.permission.functionality.data.data