import { createSlice } from "@reduxjs/toolkit"

import { addUser, deleteUser, getAllUsers, getSingleUser, handleAddUserFailed, handledeleteUserFailed, handlegetAllUsersFailed, handleGetSingleUserFailed, handleupdateUserFailed, updateUser } from "./userAction"

const initialState = {
  status: "idle",
  user:{},
  addUsers:{}

}

const usersSlice = createSlice({
  name: 'user',
  //createSlice will infer the state type from the 'initialState'argument
  initialState,
  reducers: {
    resetErrorAction: (state) => {
      delete state.authError
    },
    resetRegisterStatus: (state) => {
      delete state.addUsers
    }
  },

  extraReducers: (builder) => {
    builder
      // add user 
      .addCase(addUser.fulfilled, (state, action) => {
        state.status = "idle"
        state.addUsers = action.payload
        })
      .addCase(addUser.rejected, handleAddUserFailed)

      .addCase(addUser.pending, (state) => {
        state.status = "loading"
      })

       // getAll user 
       .addCase(getAllUsers.fulfilled, (state, action) => {
        state.status = "idle"
        state.user = action.payload
        })
      .addCase(getAllUsers.rejected, handlegetAllUsersFailed)

      .addCase(getAllUsers.pending, (state) => {
        state.status = "loading"
      })


      .addCase(updateUser.fulfilled, (state, action) => {
        state.status = "idle"
        state.updateUsers = action.payload
        })
      .addCase(updateUser.rejected, handleupdateUserFailed)
      .addCase(updateUser.pending, (state) => {
        state.status = "loading"

      })

        // delete  user 
        .addCase(deleteUser.fulfilled, (state, action) => {
          state.status = "idle"
          state.delete = action.payload
          })
        .addCase(deleteUser.rejected, handledeleteUserFailed)
  
        .addCase(deleteUser.pending, (state) => {
          state.status = "loading"
        })

       // get single  user 
       .addCase(getSingleUser.fulfilled, (state, action) => {
        state.status = "idle"
        state.userDetail = action.payload
        })
      .addCase(getSingleUser.rejected, handleGetSingleUserFailed)

      .addCase(getSingleUser.pending, (state) => {
        state.status = "loading"
      })
  }
})
export const { resetErrorAction, resetRegisterStatus } = usersSlice.actions
export default usersSlice.reducer
export const selectStatus = (state) => state.user.status
export const addUserResponse = (state) => state && state.user && state.user.addUsers && state.user.addUsers.data
export const deleteResponse = (state) => state && state.user && state.user.delete && state.user.delete.data
export const getAlluser = (state) => state && state.user && state.user.user && state.user.user.data && state.user.user.data.user

export const getSingleuser = (state) => state && state.user && state.user.userDetail && state.user.userDetail.data && state.user.userDetail.data.data